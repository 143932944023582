import React, { FC } from 'react';
import styles from './PaymnetExtractResume.module.scss';
import { IExtract } from '../../../../../modules/consume/domain/models/Extract';
import { moneyFormater } from '../../../../../services/utils/Money';

interface IPaymnetExtractResumeProps {
    extract: IExtract; payServiceFee: boolean; totalToPay: number; partialAmount: number; discount?: number; appliedDiscount?: number;
}
export const PaymnetExtractResume: FC<IPaymnetExtractResumeProps> = ({ extract, payServiceFee, totalToPay, partialAmount, appliedDiscount, discount }) => {
    return <div className={styles.extractResume}>
        <b>Resumo da conta</b>
        <div className={styles.total}>
            <label>Subtotal:</label><span>{moneyFormater(extract.totalAmount)}</span>
        </div>
        {/* {!!extract.serviceFeeConfig.serviceFeePercentage && */}
        {!!extract.serviceFeeConfig &&
            <div className={styles.total} style={{ textDecoration: payServiceFee ? "" : "solid line-through var(--secondary-color) 2px" }}>
                <label>Taxa de serviço: ({extract.serviceFeeConfig?.serviceFeePercentage * 100}%)</label><span> + {moneyFormater(extract.serviceFeeConfig.serviceFeeAmount)}</span>
            </div>}
        <div className={styles.total}>
            <label>Total consumido:</label><span>{moneyFormater(extract.totalAmount)}</span>
        </div>
        <div className={styles.total}>
            <label>Total pago:</label><span>{moneyFormater(extract.paidAmount)}</span>
        </div>
        {discount && <div className={styles.total}>
            <label>Desconto:</label><span>{moneyFormater(-discount)}</span>
        </div>}
        <div className={styles.total}>
            <label>Total restante:</label><span>{moneyFormater(totalToPay - (discount ?? 0))}</span>
        </div>
        {!!appliedDiscount && <div className={styles.total}>
            <label>Desconto já aplicado:</label><span>{moneyFormater(-appliedDiscount)}</span>
        </div>}
        <div className={styles.partial}>
            <div className={styles.value}>
                <label>Valor a ser pago:</label><span>{moneyFormater(partialAmount)}</span>
            </div>
            {partialAmount !== totalToPay &&
                <div className={styles.remain}>
                    <label>Valor restante:</label><span>{moneyFormater((payServiceFee ? extract.totalToPayWithFee : extract.totalToPayWithoutFee) - partialAmount - (discount ?? 0))}</span>
                </div>
            }
        </div>
    </div>;
};
