import React, { FC, RefObject, useCallback, useEffect, useMemo } from 'react'
import styles from './_PayTicketOrderForm.module.scss'
import { PaymentType } from '../../../../modules/consume/domain/models/PaymentType';
import { Button, Drawer, Icon, IconButton } from '@mui/material';
import { scrooToElement } from '../../../../cross-cutting/ScrollToElement';
import { PaymentSelector } from '../paymentTypeSelector/PaymentSelector';
import { PaymentOrderResume } from './ticketOrderResume/PaymentOrderResume';
import { IOrderItem } from '../../../../modules/cart/domain/models/IOrderItem';
import ISimplePos from '../../../../modules/simplePos/model/ISimplePos';
import { SimplePosSelector } from '../SimplePosSelector/SimplePosSelector';
import UseDeviceStore from '../../../../modules/device/store/DeviceStore';
import CustomerForm from '../../../TableMap/components/customerForm/CustomerForm';
import { ICustomerFormValue } from '../../../TableMap/components/customerForm/ICustomerFormValue';
import Discount, { IDiscountValue } from '../discount/Discount';
import ObservationInput from '../observationInput/ObservationInput';

export interface ICloseAccountFormProps {
    //propertys
    onClose?: () => void;
    orderItems: IOrderItem[];
    amountToPay: number;
    onSubmit: (paymentType: PaymentType, customerValues?: ICustomerFormValue, amount?: number, simplePos?: ISimplePos | null, discount?: IDiscountValue, notes?: string) => void;
    simplePosList?: ISimplePos[];
    enableDiscount?: boolean;
}

const PayTicketForm: FC<ICloseAccountFormProps> = ({ onClose, onSubmit, amountToPay, orderItems, simplePosList, enableDiscount }) => {
    const [deviceConfig] = UseDeviceStore(state => [state.deviceConfig]);
    const [simplePos, setSimplePos] = React.useState<ISimplePos | null>(null);
    const [paymentType, setPaymentType] = React.useState<PaymentType>();
    const [customerValues, setCustomerValues] = React.useState<ICustomerFormValue>();
    const [hasCustomerData, setHasCustomerData] = React.useState(false);
    const [checkCustomerData, setCheckCustomerData] = React.useState(false);
    const [discount, setDiscount] = React.useState<IDiscountValue>();
    const [notes, setNotes] = React.useState<string>();
    const [discountConfig, setDiscountConfig] = React.useState(false);

    const handleTicketCustomerData = useMemo(() => deviceConfig?.operation.methods.find((method) => method.name === "Pré-pago Ficha")?.customerIdentification, [deviceConfig?.operation.methods]);

    const onClickPaymentButtonHandle = useCallback(() => {
        if (!paymentType) return;
        if (paymentType === PaymentType.cielo && simplePosList?.length && simplePos === undefined) return;
        if (!checkCustomerData && handleTicketCustomerData?.some((item) => item.enabled)) {
            return setHasCustomerData(true);
        }
        onSubmit(paymentType, customerValues, amountToPay, paymentType === PaymentType.cielo ? simplePos : undefined, discount, notes);

    }, [paymentType, simplePosList?.length, simplePos, checkCustomerData, handleTicketCustomerData, onSubmit, customerValues, amountToPay, discount, notes]);

    const onSubmitCustomerData = useCallback((values?: ICustomerFormValue) => {
        setCustomerValues(values);
        setCheckCustomerData(true);
        setHasCustomerData(false);
    }, []);

    const validate = useMemo(() => {
        if (!paymentType) {
            return false
        }
        if (orderItems && orderItems.length === 0) {
            return false
        }
        if (paymentType === PaymentType.cielo) {
            // if (!simplePosList?.length) {
            //     return false;
            // }
            if (simplePos === undefined) {
                return false;
            }
        }
        return true
    }, [paymentType, orderItems, simplePos]);

    useEffect(() => {
        if (simplePosList && simplePosList.length) {
            setSimplePos(simplePosList[0]);
        }
    }, [simplePosList])


    const onFocusInput = useCallback((inputEvent: RefObject<HTMLInputElement>) => {
        scrooToElement(inputEvent);
    }, [])

    if (discountConfig) {
        return (
            <Discount
                total={amountToPay}
                remaining={amountToPay}
                currentDiscount={discount}
                updateDiscount={setDiscount}
                discountConfig={discountConfig}
                setDiscountConfig={setDiscountConfig}
            />
        )
    }

    return (
        <div id={styles.PayTicketForm} >
            <div className={styles.header} >
                <IconButton onClick={discountConfig ? () => setDiscountConfig(false) : onClose} className={styles.closeButton}><Icon >close</Icon></IconButton>
                <h2>Pagamento</h2>
            </div>
            <div className={styles.container} >
                <div className={styles.content} >
                    <PaymentOrderResume
                        orderItems={orderItems}
                        totalToPay={amountToPay}
                        discount={discount?.amount}
                    />

                    {amountToPay > 0 && enableDiscount && (
                        <Discount
                            total={amountToPay}
                            remaining={amountToPay}
                            currentDiscount={discount}
                            updateDiscount={setDiscount}
                            discountConfig={discountConfig}
                            setDiscountConfig={setDiscountConfig}
                        />
                    )}

                    <PaymentSelector
                        paymentType={paymentType}
                        onChangePaymentType={setPaymentType}
                        onFocusInput={onFocusInput}
                        amountToPay={amountToPay - (discount?.amount ?? 0)}
                        // showCielo={simplePosList && simplePosList.length > 0}
                        showCielo
                    />
                    {
                        paymentType === PaymentType.cielo && simplePosList && simplePosList.length > 0 &&
                        <SimplePosSelector
                            onChangeSimplePos={setSimplePos}
                            selectedSimplePos={simplePos}
                            simplePosList={simplePosList}
                        />
                    }
                </div>
                <div className={styles.footer}>
                    <ObservationInput onChange={setNotes} rows={3} />
                    <Button
                        variant={'contained'}
                        disabled={!validate}
                        size='large'
                        color='primary'
                        className={styles.button}
                        onClick={onClickPaymentButtonHandle}>
                        Enviar pagamento
                    </Button>
                </div>
            </div>

            <Drawer open={hasCustomerData} anchor='right' onClose={() => setHasCustomerData(false)}>
                <div>
                    <CustomerForm onClose={() => setHasCustomerData(false)} onSubmit={onSubmitCustomerData} handleCustomerData={handleTicketCustomerData} />
                </div>
            </Drawer>
        </div >
    )
}
export default PayTicketForm


