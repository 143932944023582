import React, { FC, useCallback, useEffect, useMemo } from 'react'
import styles from './Discount.module.scss'
import { Icon, IconButton, Button } from '@mui/material';
import MoneyInput from '../../../TableMap/components/partialPaymentInput/MoneyInput';
import PercentInput from '../../../TableMap/components/percentInput/PercentInput';
import ObservationInput from '../observationInput/ObservationInput';
import { AttachMoney, Close, DiscountOutlined, KeyboardArrowLeft, KeyboardArrowRight, Percent } from '@mui/icons-material';

export interface IDiscountValue {
	amount: number;
	reason: string;
	isPercentage: boolean;
}

export enum DiscountEnumType {
	PERCENTAGE,
	AMOUNT,
}

export interface IDiscountProps {
	//propertys
	total: number;
	remaining: number;
	currentDiscount?: IDiscountValue;
	updateDiscount: (discount?: IDiscountValue) => void;
	appliedDiscounts?: number;
	discountConfig: boolean;
	setDiscountConfig: React.Dispatch<React.SetStateAction<boolean>>;
}
const Discount: FC<IDiscountProps> = ({
	total,
	remaining,
	currentDiscount,
	updateDiscount,
	appliedDiscounts,
	discountConfig,
	setDiscountConfig
}) => {
	const [discountType, setDiscountType] = React.useState<DiscountEnumType>(DiscountEnumType.AMOUNT);

	const [value, setValue] = React.useState<number>(0);
	const [percentValue, setPercentValue] = React.useState<number>(0);
	const [reason, setReason] = React.useState<string>('');

	useEffect(() => {
		if (currentDiscount?.amount) {
			setValue(currentDiscount.amount)
			setPercentValue((currentDiscount.amount * 100) / total)
			setReason(currentDiscount.reason)
			setDiscountType(currentDiscount.isPercentage ? DiscountEnumType.PERCENTAGE : DiscountEnumType.AMOUNT)
		}
	}, [currentDiscount, total]);


	const onChangeHandle = useCallback((_value: number) => {
		if (total <= _value) {
			setValue(total)
			setPercentValue(100)
			return
		}
		setValue(_value);
		setPercentValue(Number(((_value * 100) / total).toFixed(2)));
	}, [total])

	const onChangePercentHandle = useCallback((_value: number) => {
		if (_value >= 100) {
			setPercentValue(100)
			setValue(total)
			return
		}
		setPercentValue(_value);
		setValue(Number(((_value * total) / 100).toFixed(2)));
	}, [total])


	const onClearDiscountHandler = () => {
		updateDiscount(undefined);
	}

	const onEditDiscountHandler = () => {
		setDiscountConfig(true);
	}

	const onClosedHandler = () => {
		setDiscountConfig(false);
	}

	const percentDicount = ((currentDiscount?.amount ?? 0) * 100) / total;

	const onChangeTypeHandler = (type: DiscountEnumType) => {
		setDiscountType(type);
	}

	const onChageReasonHandler = (value: string) => {
		setReason(value);
	}
	const normalizedValue = (value: number) => Number(value.toFixed(2))

	const onSubmmitHandler = useCallback(() => {
		updateDiscount({ amount: normalizedValue(value), reason, isPercentage: discountType === DiscountEnumType.PERCENTAGE });
		setDiscountConfig(false);
	}, [updateDiscount, value, reason, discountType, setDiscountConfig])

	const remainingPercent = useMemo(() => Number(((remaining * 100) / total).toFixed(2)), [remaining, total])
	const appliedDiscountPercent = normalizedValue(((appliedDiscounts ?? 0) * 100) / total);

	if (discountConfig) {
		return (
			<div id={styles.DiscountModal}>
				<div className={styles.header}>
					<div>
						<IconButton onClick={onClosedHandler} size='small'>
							<KeyboardArrowLeft />
						</IconButton>
						<h2>Desconto</h2>
					</div>
					<IconButton onClick={onClosedHandler} size='small'>
						<Close />
					</IconButton>
				</div>
				<div className={styles.content}>
					<p>Tipo de desconto</p>
					<div className={styles.typeContainer}>
						<button
							onClick={() => onChangeTypeHandler(DiscountEnumType.AMOUNT)}
							className={discountType === DiscountEnumType.AMOUNT ? styles.typeActive : styles.type}
						>
							<AttachMoney />
							Valor
						</button>
						<button
							onClick={() => onChangeTypeHandler(DiscountEnumType.PERCENTAGE)}
							className={discountType === DiscountEnumType.PERCENTAGE ? styles.typeActive : styles.type}
						>
							<Percent />
							Porcentagem
						</button>
					</div>

					{discountType === DiscountEnumType.PERCENTAGE
						? <div className={styles.inputValue} >
							{/*{remainingPercent}*/}
							<PercentInput
								//onFocus={() => onFocus?.(inputPercentRef)}
								onChange={onChangePercentHandle}
								defaultValue={percentValue}
								maxValue={remainingPercent ?? 100}
								//maxValue={remainingPercent ?? 100 ?? 0}
								label='Porcentagem'
								labelStyle={{ fontWeight: "normal", fontSize: "14px", lineHeight: "150%", marginBottom: "4px" }}
							/>
						</div>
						: <div className={styles.inputValue}>
							<MoneyInput
								//onFocus={() => onFocus?.(inputRef)}
								onChange={onChangeHandle}
								defaultValue={value}
								maxValue={remaining ?? total ?? 0}
								label='Valor'
								labelStyle={{ fontWeight: "normal", fontSize: "14px", lineHeight: "150%", marginBottom: "4px" }}
							/>
						</div>
					}

					<div className={styles.valuesContainer}>
						<b>Resumo do desconto</b>
						<div>
							<span>Total da conta</span>
							<b>R$ {total.toFixed(2).replace('.', ',')}</b>
						</div>
						{!!appliedDiscountPercent && appliedDiscounts && <div>
							<span>Desconto aplicado ({appliedDiscountPercent}%)</span>
							<b>{appliedDiscounts?.toFixed(2).replace('.', ',')}</b>
						</div>}
						<div style={{ color: "#399C54" }}>
							<span>Desconto aplicado: ({normalizedValue(percentValue)}%) </span>
							<b>R${value.toFixed(2).replace('.', ',')}</b>
						</div>
						<div style={{ borderTop: "1px dashed #32008E", paddingTop: "10px" }}>
							<span>Valor a ser pago</span>
							<b>{(remaining - value).toFixed(2).replace('.', ',')}</b>
						</div>
					</div>

					<ObservationInput
						label='Motivo'
						onChange={onChageReasonHandler}
						rows={3}
						required
						labelStyle={{ fontWeight: "normal", fontSize: "14px", lineHeight: "150%", marginBottom: "4px" }}
						value={reason}
						placeHolder='Digite o motivo do desconto'
					/>

					<div className={styles.endButtons}>
						<Button
							variant='outlined'
							onClick={onClosedHandler}
						>
							Voltar
						</Button>
						<Button
							variant='contained'
							onClick={onSubmmitHandler}
							disabled={value <= 0}
						>
							Aplicar desconto
						</Button>
					</div>
				</div>
			</div>
		)
	}
	
	return (
		<div id={styles.Discount}>
			<b>Desconto</b>
			<div
				className={styles.button}
				//onClick={currentDiscount && onEditDiscountHandler}
			>
				<div
					className={styles.label}
					style={{ color: currentDiscount && currentDiscount?.amount > 0 ? 'green' : 'var(--primary-color)' }}
				>

					<DiscountOutlined />
					{currentDiscount && currentDiscount?.amount > 0 ? (
						<div>
							<div>Desconto aplicado: R$ {Number(currentDiscount?.amount.toFixed(2)).toFixed(2).replace('.', ',')} ({normalizedValue(percentDicount)}%)</div>
						</div>) :
						<div>
							<div>Adicionar desconto</div>
						</div>
					}
				</div>
				<div className={styles.actions}>
					{currentDiscount && (
						<IconButton onClick={onClearDiscountHandler} size="small">
							<Close className={styles.icon} fontSize="small"/>
						</IconButton>
					)}
					<IconButton onClick={onEditDiscountHandler} size="small">
						<KeyboardArrowRight className={styles.icon} fontSize="small"/>
					</IconButton>
				</div>
			</div>
		</div>
	)
}
export default Discount


/*

import {Text} from '@/components/atoms/text/Text';
import React, {memo, useCallback, useEffect, useRef} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {useDiscountSelectorStyles} from './DiscountSelector.styles';
import {IDiscountSelectorProps} from './DiscountSelector.types';
import {useLanguage} from '@/context/language/Language';
import {LogError} from '@/utils/functions/Log';
import {getMoneyFormat} from '@/utils/functions/getMoneyFormat';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
//import DiscountModal from '../discountModal/DiscountModal';
import {useTranslation} from 'react-i18next';
import DiscountModal from '../discountModalV2/DiscountModal';
import {IDiscountModalHandler} from '../discountModalV2/DiscountModal.types';
import {NumberHelper} from '@/utils/functions/NumberHelper';
import {IconButton} from '@/components/atoms/iconButton/IconButton';

const DiscountSelector: React.FC<IDiscountSelectorProps> = ({
  total,
  remaining,
  currentDiscount,
  updateDiscount,
  appliedDiscount,
  style,
}) => {
  const discountModalRef = useRef<IDiscountModalHandler>(null);

  const {locale} = useLanguage();
  const {theme, styles} = useDiscountSelectorStyles();
  const {t} = useTranslation('Discount');

  const handleDiscountChange = useCallback(
	(_value: number, _reason?: string) => {
	  updateDiscount({
		amount: _value,
		reason: _reason,
	  });
	},
	[updateDiscount],
  );

  const openModal = useCallback(async () => {
	try {
	  const result = await discountModalRef.current?.open(total, remaining, currentDiscount, appliedDiscount);
	  if (result) {
		handleDiscountChange(result.amount, result.reason);
	  }
	} catch (error) {
	  LogError(error);
	}
  }, [appliedDiscount, currentDiscount, handleDiscountChange, remaining, total]);

  const percentDicount = NumberHelper.normalize(((currentDiscount?.amount ?? 0) * 100) / total);
  const appliedDiscountPercent = NumberHelper.normalize(((appliedDiscount ?? 0) * 100) / total);

  useEffect(() => {
	if (percentDicount > 100) {
	  handleDiscountChange(0);
	}
  }, [handleDiscountChange, percentDicount]);

  if (!remaining) {
	return null;
  }

  return (
	<>
	  <View style={[styles.appliedContainer, style]}>
		{!!appliedDiscount && appliedDiscount > 0 && (
		  <View style={styles.result}>
			<View style={styles.label}>
			  <Icon name="ticket-outline" style={styles.icon} color={theme.colors.secondary_dark} size={18} />
			  <Text size={14} ms={8}>
				{'Desconto aplicado'}
			  </Text>
			</View>
			<View style={styles.values}>
			  <Text bold size={12}>
				{getMoneyFormat(appliedDiscount ?? 0, locale)}
			  </Text>
			  <Text bold size={12}>
				({appliedDiscountPercent}%)
			  </Text>
			</View>
		  </View>
		)}
	  </View>
	  <View style={[styles.container, style]}>
		<View style={styles.label}>
		  <Icon name="ticket-outline" style={styles.icon} color={theme.colors.secondary_dark} size={18} />
		  <Text size={14} ms={8}>
			{'Desconto'}
		  </Text>
		</View>
		{!!currentDiscount && currentDiscount.amount > 0 ? (
		  <View style={styles.result}>
			<View style={styles.values}>
			  <Text bold size={12}>
				{getMoneyFormat(currentDiscount.amount ?? 0, locale)}
			  </Text>
			  <Text bold size={12}>
				({percentDicount}%)
			  </Text>
			</View>
			<TouchableOpacity onPress={openModal} style={styles.button}>
			  <Text color={theme.colors.primary} size={12}>
				{t('modify')}
			  </Text>
			</TouchableOpacity>
			<IconButton onPress={() => handleDiscountChange(0)} style={styles.button}>
			  <Icon name="close" color={theme.colors.primary} size={18} />
			</IconButton>
		  </View>
		) : (
		  <TouchableOpacity onPress={openModal} style={styles.button}>
			<Text size={12}>{t('add')}</Text>
		  </TouchableOpacity>
		)}
	  </View>
	  <DiscountModal ref={discountModalRef} />
	</>
  );
};

export default memo(DiscountSelector);
*/