import React, { FC, ReactNode } from 'react'
import styles from './Keyboard.module.scss'
import { Button, Icon, TextField } from '@mui/material'
export interface IKeyBoardInputProps {
    id?: string;
    onSubmit?: (ref: React.RefObject<HTMLInputElement>, value: string) => void
    error?: string;
    multiline?: boolean
    lengthToEnter?: number
    placeHolder?: string
    inputRef: React.RefObject<HTMLInputElement>
    password?: boolean
    label?: string
    autoFocus?: boolean
    inputMode?: 'numeric' | 'text' | 'none' | 'tel' | 'search' | 'url' | 'email' | 'decimal',
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
    endAdornment?: React.ReactNode
    onChange?: (value: string) => void
    size?: 'small' | 'medium'
    showSubmitButton?: boolean;
    submitButtonLabel?: ReactNode;
    focusOnSubmit?: boolean;
    required?: boolean;
    rows?: number;
    className?: string;
    labelStyle?: React.CSSProperties;
    value?: string;
}



const KeyboardInput: FC<IKeyBoardInputProps> = ({
    onSubmit,
    id,
    lengthToEnter,
    focusOnSubmit,
    inputRef,
    label,
    placeHolder,
    password,
    inputMode,
    onFocus,
    onChange,
    error,
    endAdornment,
    multiline,
    autoFocus,
    size = 'small',
    showSubmitButton,
    submitButtonLabel,
    required = false,
    rows,
    className,
    labelStyle,
    value
}) => {

    const onChangeHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event.target.value);
        if (lengthToEnter && event.target.value.length >= lengthToEnter) {
            onSubmit?.(inputRef, event.target.value);
            const input = inputRef.current;
            if (!input) return;
            input.value = "";
        }
    };

    const onKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // event.preventDefault();
        if (event.key === 'Enter' && event.shiftKey === false) {
            const input = inputRef.current;
            if (!input) return;
            onSubmit?.(inputRef, input.value);
            inputRef.current.setSelectionRange(0, inputRef.current.value.length);
            if (!focusOnSubmit) {
                inputRef.current.blur();
            }// input.value = "";
        }
    }

    const onClickSendHnadler = () => {
        const input = inputRef.current;
        if (!input) return;
        onSubmit?.(inputRef, input.value);
        inputRef.current.setSelectionRange(0, inputRef.current.value.length);
        if (!focusOnSubmit) {
            inputRef.current.blur();
        }
    }

    return (
        <div className={styles.inputContainer} >
            <label htmlFor='numeric-keyboard' className={`${styles.label} ${required ? styles.required : ""}`} style={labelStyle}>{label}</label>
            <TextField
                size={size}
                autoFocus={autoFocus}
                onKeyDown={onKeydown}
                placeholder={placeHolder}
                multiline={multiline}
                onFocus={onFocus}
                className={className}
                // focused={focused}
                rows={rows}
                error={!!error}
                helperText={error}
                inputProps={{
                    id: id,
                    inputMode: inputMode,
                    type: password ? 'password' : null, // Enable password mode
                    onChange: onChangeHandle,
                }}
                InputProps={
                    {
                        endAdornment: showSubmitButton ? <Button variant='contained' onClick={onClickSendHnadler}>{submitButtonLabel ?? <Icon>check</Icon>}</Button> : endAdornment
                    }
                }
                inputRef={inputRef}
                fullWidth
                value={value}
            />
            {/* <Button variant='contained'><Icon>send</Icon></Button> */}
        </div>
    )
}
export default KeyboardInput