import React, { FC, useEffect } from 'react'
import styles from './ObservationInput.module.scss'
import KeyboardInput from '../../../../components/_keyboard/_KeyboardInput'
import KeyboardModal from '../../../../components/_keyboard/_KeyboardModal'
import Keyboard from '../../../../components/_keyboard/Keyboard'
import { useKeyboard } from '../../../../components/_keyboard/KeyboardContext'
export interface IObservationInputProps {
    //propertys
    onChange?: (value: string) => void
    onFocusInput?: (inputRef: React.RefObject<HTMLInputElement>) => void
    onSubmit?: (value: string) => void
    error?: string
    label?: string
    rows?: number
    required?: boolean
    labelStyle?: React.CSSProperties
    value?: string
    placeHolder?: string
}
const ObservationInput: FC<IObservationInputProps> = ({
    onChange,
    onFocusInput,
    onSubmit,
    error,
    label,
    rows,
    required,
    labelStyle,
    value,
    placeHolder
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const { configureKeyboard } = useKeyboard()

    const onFocusHandle = React.useCallback(() => {
        configureKeyboard({
            inputRef,
            onChange,
            type: 'qwerty'
        })
        onFocusInput?.(inputRef)
    }, [configureKeyboard, onChange, onFocusInput])
    
    return (
        <>
            <div id={styles.ObservationInput} >
                <KeyboardInput
                    error={error}
                    multiline
                    rows={rows}
                    className={styles.input}
                    onSubmit={(_, value) => onSubmit?.(value)}
                    inputRef={inputRef}
                    onFocus={onFocusHandle}
                    label={label ?? 'Observação'}
                    onChange={onChange}
                    required={required}
                    labelStyle={labelStyle}
                    value={value}
                    placeHolder={placeHolder}
                />
            </div>
            {/* <KeyboardModal
                inputRef={inputRef}
            >
                <Keyboard
                    inputRef={inputRef}
                    type='qwerty'
                    onChange={onChange} />
            </KeyboardModal> */}
        </>
    )
}
export default ObservationInput