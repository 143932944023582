import React, { FC, RefObject, useCallback, useEffect, useState } from 'react'
import styles from './PercentInput.module.scss'
import KeyboardInput from '../../../../components/_keyboard/_KeyboardInput'
import { useKeyboard } from '../../../../components/_keyboard/KeyboardContext';
import { getCombinationOfNotes } from '../../../../cross-cutting/SugestionNotesMoney';
import { Button } from '@mui/material';
import { moneyMaskNumber, floatMaskNumber, textToFloat } from '../../../../cross-cutting/moneyMask';

export interface IPercentInputProps {
    //propertys
    onChange?: (value: number) => void,
    defaultValue?: number;
    maxValue?: number
    label?: string
    sugestion?: boolean
    onFocus?: (inputRef: RefObject<HTMLInputElement>) => void
    labelStyle?: React.CSSProperties
}

const PercentInput: FC<IPercentInputProps> = ({ onChange, maxValue, defaultValue, label, onFocus, sugestion, labelStyle }) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const { configureKeyboard } = useKeyboard();
    const [combinations, setCombinations] = useState<number[]>();

    useEffect(() => {
        if (defaultValue) {
            if (inputRef.current) {
                inputRef.current.value = floatMaskNumber(defaultValue);
                // inputRef.current.setSelectionRange(0, inputRef.current.value.length);
                const combination = getCombinationOfNotes(defaultValue);
                setCombinations(combination);
            }
        }
    }, [defaultValue])


    const onChangeHandle = useCallback((value: string) => {
        if (inputRef.current) {
            const _moneyValue = textToFloat(value)
            let _value = maxValue !== undefined && maxValue !== null && _moneyValue > maxValue ? maxValue : _moneyValue
            inputRef.current.value = floatMaskNumber(_value)
            onChange?.(_value)
        }
    }, [maxValue, onChange])

    const onSubmit = useCallback(() => {
        inputRef.current?.blur();
    }, [])

    const onClickChangeSugestion = useCallback((_value: number) => {
        onChangeHandle(moneyMaskNumber(_value))
    }, [onChangeHandle])



        const onFocusHandle = useCallback((inputRef: RefObject<HTMLInputElement>) => {
    
            onFocus?.(inputRef)
            configureKeyboard({
                inputRef: inputRef,
                type: 'numeric',
                onChange: onChangeHandle,
                onSubmit: onSubmit,
            })
        }, [onFocus, configureKeyboard, onChangeHandle, onSubmit])

    return (
        <>
            {sugestion &&
                <div className={styles.sugestionChange}>
                    {maxValue && <Button variant='text' className={styles.sugestionItem} onClick={() => onClickChangeSugestion(maxValue)}>{moneyMaskNumber(defaultValue)}</Button>}
                    {combinations?.map(item => <Button key={item} variant='text' className={styles.sugestionItem} onClick={() => onClickChangeSugestion(item)}>{moneyMaskNumber(item)}</Button>)}
                </div>
            }
            <KeyboardInput onFocus={() => onFocusHandle?.(inputRef)} onChange={onChangeHandle} onSubmit={onSubmit} inputMode='numeric' inputRef={inputRef} label={label} labelStyle={labelStyle} value={`${defaultValue?.toFixed(2).replace(".", ",")}%`} />
        </>
    )
}

export default PercentInput;
