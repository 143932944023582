import React, { FC, useMemo } from 'react';
import styles from './PaymentOrderResume.module.scss';
import { IExtract } from '../../../../../modules/consume/domain/models/Extract';
import { moneyFormater } from '../../../../../services/utils/Money';
import { IOrder } from '../../../../../modules/cart/domain/models/IOrder';
import { IOrderItem } from '../../../../../modules/cart/domain/models/IOrderItem';

interface IPaymentOrderResumeProps {
    orderItems: IOrderItem[];
    totalToPay: number;
    discount?: number;
}
export const PaymentOrderResume: FC<IPaymentOrderResumeProps> = ({ orderItems, totalToPay, discount }) => {

    var percentDiscount = useMemo(() => {
        if (discount && totalToPay) {
            return (discount / totalToPay) * 100;
        }
        return 0;
    }, [discount, totalToPay]);
    return <div className={styles.orderResume}>
        <b>Resumo do pedido</b>

        {
            orderItems.map((item, index) => {
                return <div key={index} className={styles.total}>
                    <label>{item.quantity} - {item.name} ({moneyFormater(item.price)}):</label><span>{moneyFormater(item.price * item.quantity)}</span>
                </div>
            })
        }


        <div className={styles.partial}>

            {discount && <>
                <div className={styles.value}>
                    <label>Total:</label><span>{moneyFormater(totalToPay)}</span>
                </div>

                <div className={styles.value}>

                    <label>Desconto aplicado ({Number(percentDiscount.toFixed(2))}%):</label>
                    <span>-R$ {Number(discount?.toFixed(2)).toFixed(2).replace('.', ',')}</span>

                </div>
            </>
            }
            <div className={styles.value}>
                <label>Valor a ser pago:</label><span>{moneyFormater(totalToPay - (discount ?? 0))}</span>
            </div>
        </div>
    </div >;
};
